import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Image, Card, Button } from 'react-bootstrap';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../../Utils';
import ArticleContent from './ArticleContent'; // Import the component
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';

const ArticleDetail = () => {
  const { slug } = useParams();
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [article, setArticle] = useState(null);
  const [limit, setLimit] = useState(3);
  const [latestArticles, setLatestArticles] = useState([]);

  const articleRef = useRef(null);

  const fetchArticle = useCallback(
    async (slug) => {
      try {
        const { data } = await Axios.get(`${appBaseUrl}/api/articles/${slug}`);
        setArticle(data);
      } catch (err) {
        toast.error(getError(err));
      }
    },
    [appBaseUrl]
  );

  const fetchBottomArticles = useCallback(async () => {
    if (article?._id) {
      try {
        const latestResults = await Axios.get(
          `${appBaseUrl}/api/articles/random`,
          {
            params: { limit },
          }
        );
        const randomData = latestResults?.data || [];

        // Filter out the article that has the same id as the `id` parameter
        const filteredRandomArticles = randomData.filter(
          (art) => art._id !== article._id
        );

        // Take only the first 2 articles from the filtered list
        const limitedArticles = filteredRandomArticles.slice(0, 2);

        setLatestArticles(limitedArticles);
      } catch (err) {
        toast.error(`Error: ${err.message}`);
      }
    }
  }, [article?._id, appBaseUrl, limit]);

  useEffect(() => {
    fetchArticle(slug);
  }, [slug, fetchArticle]);

  useEffect(() => {
    setLimit(3);
    fetchBottomArticles();
  }, [slug, fetchBottomArticles]);

  if (!article) {
    return <p>Loading...</p>;
  }

  const shareUrl = window.location.href;

  // Generate excerpt from content
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const trimText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;

    const trimmedText = text.substr(0, maxLength);
    return `${trimmedText.substr(
      0,
      Math.min(trimmedText.length, trimmedText.lastIndexOf(' '))
    )}...`;
  };

  const getExcerpt = (htmlContent, maxLength) => {
    const plainText = stripHtmlTags(htmlContent);
    return trimText(plainText, maxLength);
  };

  return (
    <div id="article-container" ref={articleRef}>
      <Container className="article">
        <Row className="my-4">
          <Col md={3}>
            <div className="left-sidebar">
              {/* Add your left sidebar content here */}
            </div>
          </Col>
          <Col md={6}>
            <h1 style={{ textAlign: 'center', fontSize: '32px' }}>
              {article.name}
            </h1>
            <div className="article-image my-4">
              <Image src={article.imageUrl} alt={article.name} fluid />
            </div>
            <div className="article-content">
              <ArticleContent content={article.description} />
            </div>
            <div
              className="share-buttons my-4"
              style={{ display: 'flex', gap: '10px' }}
            >
              <FacebookShareButton url={shareUrl} quote={article.name}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <WhatsappShareButton url={shareUrl} title={article.name}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FontAwesomeIcon
                icon={faShareNodes}
                size="2x"
                style={{ cursor: 'pointer' }}
              />
            </div>
            <Row className="my-4 articles detail g-3">
              {latestArticles.map((article, i) => (
                <Col md={12} lg={6} key={i}>
                  <Card>
                    <div className="image-container">
                      <Card.Img
                        variant="top"
                        src={article?.imageUrl}
                        alt={article.name}
                      />
                    </div>
                    <Card.Body>
                      <Card.Title>{article?.name}</Card.Title>
                      <Card.Text>
                        {getExcerpt(article?.description, 150)}
                      </Card.Text>
                      <Link
                        className="read-more"
                        to={`/article/${article.slug}`}
                      >
                        <Button variant="primary">Read More</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={3}>
            <div className="right-sidebar">
              {/* Add your right sidebar content here */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ArticleDetail;
