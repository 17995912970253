import React, { useEffect } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { getError } from '../../Utils';
import ArticleExcerpt from './ArticleExcerpt';

function CategoryDetail() {
  const { slug } = useParams();
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [category, setCategory] = useState([]);
  const [articles, setArticles] = useState([]);
  const [limit] = useState(9); // State for limit
  const [page, setPage] = useState(1); // Current page
  const [totalArticles, setTotalArticles] = useState(0); // Total articles count

  const fetchCategory = async (slug) => {
    try {
      const { data } = await Axios.get(`${appBaseUrl}/api/categories/${slug}`);
      setCategory(data);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const fetchArticles = async (categoryId, page) => {
    try {
      const { data } = await Axios.get(
        `${appBaseUrl}/api/articles/category-detail`,
        {
          params: { categoryId, limit, page },
        }
      );

      setArticles(data?.articles);
      setTotalArticles(data?.totalArticles);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    // Reset page to 1 when category changes
    fetchCategory(slug);
    setPage(1);
  }, [slug]);

  useEffect(() => {
    if (category._id) {
      fetchArticles(category._id, page);
    }
  }, [category._id, page]);

  const totalPages = Math.ceil(totalArticles / limit);

  return (
    <Container>
      <div className="home articles my-4">
        <h1>{category?.name}</h1>
        <p>{category?.description}</p>
        <Row className="my-4 articles g-3">
          {articles?.map((article, i) => (
            <Col md={6} lg={4} key={i}>
              <Card>
                <div className="image-container">
                  <Card.Img
                    variant="top"
                    src={article?.imageUrl}
                    alt={article.name}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{article?.name}</Card.Title>
                  <ArticleExcerpt description={article?.description} />
                  <Link className="read-more" to={`/article/${article.slug}`}>
                    <Button variant="primary">Read More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {totalPages > 1 && (
          <div className="pagination-controls">
            <Button
              variant="secondary"
              disabled={page <= 1}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </Button>
            <span className="mx-3">
              Page {page} of {totalPages}
            </span>
            <Button
              variant="secondary"
              disabled={page >= totalPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
}

export default CategoryDetail;
